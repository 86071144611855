import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '@oup/shared-front-end/src/components/Button';
import useMediaQuery from '../../utils/mediaQuery';
import styles from './MyDownloads.scss';

import withLocalizedContent from '../../language/withLocalizedContent';
import SearchInput from '../SearchInput/SearchInput';
import HubFilterBar from '../HubFilterBar/HubFilterBar';
import SVGIcon, { GLYPHS } from '../SVGIcon/SVGIcon';

import { deleteOfflineUnits, getOfflineUnits } from '../../redux/actions/offlineContentPlayer.js';
import OfflineCover from '../OfflineCover/OfflineCover';
import Checkbox from '../Checkbox/Checkbox.js';
import OfflineModal from '../../routes/Offline/OfflineModal/OfflineModal.js';

function MyDownloads({
  localizedContent: { myDownloads: content },
  key,
  storedOfflineUnits,
  getOfflineUnitsAction,
  deleteOfflineUnitsAction
}) {
  const [units, setUnits] = useState('');
  const [searchWord, setSearchWord] = useState('');
  const [searchMode, setSearchMode] = useState(false);
  const [sortOption, setSortOption] = useState('date');
  const { userId: offlineUserId } = useParams();
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [selectedIds, setSelectedIds] = useState(new Set());
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);

  useEffect(() => {
    setUnits(storedOfflineUnits);
  }, [storedOfflineUnits]);

  useEffect(() => {
    let filtered = [];
    if (units && units.length > 0) {
      filtered = units.filter(unit => {
        const isExpired = unit.date ? new Date(unit.date) < new Date() : false;
        return unit.userId === offlineUserId && !isExpired;
      });
    }
    setFilteredUnits(filtered);
  }, [units]);

  const allItemIds = useMemo(() => filteredUnits.map(item => item?.id).filter(Boolean), [filteredUnits]);
  const isAllSelected = useMemo(() => allItemIds.length > 0 && allItemIds.every(id => selectedIds.has(id)), [
    allItemIds,
    selectedIds
  ]);

  const dynamicSort = property => {
    let sortOrder = 1;
    let sortProperty = property;
    if (sortProperty[0] === '-') {
      sortOrder = -1;
      sortProperty = sortProperty.substr(1);
    }
    return (a, b) => {
      const result2 = a[sortProperty] > b[sortProperty] ? 1 : 0;
      const result = a[sortProperty] < b[sortProperty] ? -1 : result2;
      return result * sortOrder;
    };
  };

  const reOrder = sortValue => {
    const newUnits = units.sort(dynamicSort(sortValue));
    setUnits(newUnits);
  };

  const handleSearch = () => {
    getOfflineUnitsAction({ searchWord: searchWord.toLowerCase() });
    setSearchMode(true);
  };

  const handleCheckboxChange = itemId => {
    setSelectedIds(prev => {
      const newSelected = new Set(prev);
      if (newSelected.has(itemId)) {
        newSelected.delete(itemId);
      } else {
        newSelected.add(itemId);
      }
      return newSelected;
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedIds(new Set());
    } else {
      setSelectedIds(new Set(allItemIds));
    }
  };

  const handleModal = (itemId, isOneSelected) => {
    setCurrentId(itemId);
    if (isOneSelected) {
      setSelectedIds(new Set());
    }
    setIsDeleteModalOpen(prev => !prev);
  };

  const handleDeleteUnit = () => {
    if (selectedIds.size === 0 && currentId) {
      deleteOfflineUnitsAction([...currentId]);
    } else {
      deleteOfflineUnitsAction([...selectedIds]);
    }
  };

  const renderDownloadedProducts = () => (
    <div className={styles.materials}>
      <div className={styles.item}>
        <Checkbox label="Select all" value={isAllSelected} onChange={handleSelectAll} />
        <div className={styles.actions}>
          <Button
            onClick={() => handleModal(selectedIds, false)}
            text={content.delete_all_items}
            variant="filled"
            size="small"
            icon={{ component: <SVGIcon glyph={GLYPHS.ICON_DELETE} /> }}
            disabled={!selectedIds.size}
          />
        </div>
      </div>
      {filteredUnits.map((item, index) => (
        <>
          <hr className={styles.hr} />
          <div className={styles.item} key={index}>
            <div className={styles.unitDataWrapper}>
              <Checkbox value={selectedIds.has(item?.id)} onChange={() => handleCheckboxChange(item?.id)} />
              <div className={styles.cover}>
                <a href={`/offline-launch/teacher/${item.contentCode}/${offlineUserId}`}>
                  <OfflineCover isbn={item.isbn} alt={item.name} {...(item.cover && { cover: item.cover })} />
                </a>
              </div>
              <div className={styles.info}>
                <a href={`/offline-launch/teacher/${item.contentCode}/${offlineUserId}`}>
                  <div className={styles.title}>{item.name}</div>
                  <div className={styles.description}>{item.description}</div>
                </a>
              </div>
            </div>
            <div className={styles.actions}>
              <a href="#" onClick={() => handleModal([item.id], true)}>
                <SVGIcon glyph={GLYPHS.ICON_DELETE} />
                {!isMobile && content.delete_item}
              </a>
            </div>
          </div>
        </>
      ))}
    </div>
  );

  return (
    <>
      {((filteredUnits && filteredUnits.length > 0) || searchMode) && (
        <div key={key} className={styles.container}>
          <div className={styles.searchingWrapper}>
            <div className={styles.searchBar}>
              <SearchInput
                placeholder={content.search_placeholder}
                onChange={word => {
                  setSearchWord(word);
                }}
                buttonAction={() => {
                  handleSearch();
                }}
              />
            </div>
            <div className={styles.filterBar}>
              <HubFilterBar
                key=""
                idPrefix=""
                filterButtonText=""
                overlayLabelText=""
                hasSortButton={false}
                sortOptions={[
                  {
                    text: content.sort_by_date,
                    id: `sort1`,
                    name: 'sortDate',
                    value: `date:asc`,
                    checked: sortOption === 'date',
                    onChange: () => {
                      setSortOption('date');
                      reOrder('date');
                    }
                  },
                  {
                    text: content.sort_by_name,
                    id: `sort2`,
                    name: 'sortName',
                    value: `name:asc`,
                    checked: sortOption === 'name',
                    onChange: () => {
                      setSortOption('name');
                      reOrder('name');
                    }
                  }
                ]}
                sortValue={`${sortOption}:asc`}
                ariaControls="searchResults"
              />
            </div>
          </div>
          {renderDownloadedProducts()}
          <OfflineModal
            isOpen={isDeleteModalOpen}
            offlineContent={content}
            closeOfflineModal={() => handleModal()}
            confirmAction={handleDeleteUnit}
          />
        </div>
      )}
      {(!filteredUnits || (filteredUnits && filteredUnits.length === 0)) && (
        <div className={styles.noDownloads}>{content.no_downloads}</div>
      )}
    </>
  );
}

MyDownloads.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  key: PropTypes.number,
  storedOfflineUnits: PropTypes.array,
  getOfflineUnitsAction: PropTypes.func.isRequired,
  deleteOfflineUnitsAction: PropTypes.func.isRequired
};

export default compose(
  connect(
    ({ offlineContentPlayer }) => ({
      storedOfflineUnits: offlineContentPlayer.units
    }),
    {
      getOfflineUnitsAction: getOfflineUnits,
      deleteOfflineUnitsAction: deleteOfflineUnits
    }
  ),
  withLocalizedContent('myDownloads')
)(MyDownloads);
