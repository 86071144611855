import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './OfflineUserProfiles.scss';
import withLocalizedContent from '../../../language/withLocalizedContent';
import SVGIcon, { GLYPHS } from '../../../components/SVGIcon/SVGIcon';
import Button from '../../../components/Button/Button';
import ContentWithThumbnail from '../../../components/ContentWithThumbnail/ContentWithThumbnail';
import { SIZES as thumbnailSizes } from '../../../components/Thumbnail/Thumbnail';
import { toInitials } from '../../../utils/string';
import colors from '../../../globals/colors';
import { deleteOfflineProfiles } from '../../../redux/actions/offlineContentPlayer.js';
import OfflineModal from '../OfflineModal/OfflineModal.js';

function OfflineUserProfiles({
  localizedContent: { productDownload: offlineContent },
  storedOfflineProfiles,
  deleteOfflineProfilesAction
}) {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const hasOfflineProfiles = Object.keys(storedOfflineProfiles).length !== 0;
  const history = useHistory();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const confirmUserSelection = () => {
    history.push(`/downloads/${selectedUserId}`);
  };

  const handleUserClick = userId => {
    setSelectedUserId(userId);
  };

  const handleModal = () => {
    setIsDeleteModalOpen(prev => !prev);
  };

  const handleDeleteProfile = () => {
    deleteOfflineProfilesAction([selectedUserId]);
  };

  const renderUsers = () => (
    <div className={styles.materials}>
      <div>
        {Object.entries(storedOfflineProfiles).map(([userId, user]) => {
          const isSelected = userId === selectedUserId;

          return (
            <div
              className={`${styles.item} ${isSelected ? styles.selected : ''}`}
              key={userId}
              role="button"
              tabIndex={0}
              onClick={() => handleUserClick(userId)}
              onKeyDown={() => handleUserClick(userId)}
            >
              <ContentWithThumbnail
                glyph={GLYPHS.ICON_IMPERSONATION}
                size={thumbnailSizes.MEDIUM}
                text={toInitials(user.firstName, user.lastName)}
                backgroundColor={colors.PROFILE}
                thumbnailClassName={styles.userDetailsThumbnail}
              />

              <div className={styles.name}>
                <div className={styles.title}>{user.firstName}</div>
                <div className={styles.title}>{user.lastName}</div>
              </div>

              <div className={styles.actions}>
                <a
                  href=""
                  onClick={event => {
                    event.preventDefault();
                    handleModal();
                  }}
                >
                  <div className={styles.deleteButton}>
                    <SVGIcon glyph={GLYPHS.ICON_DELETE} />
                    <div className={styles.deleteButtonText}>{offlineContent.remove_from_device}</div>
                  </div>
                </a>
              </div>

              <div className={styles.userDelimiterContainer}>
                <hr className={styles.userDelimiter} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div>
      <h1 className={styles.chooseUserText}>{offlineContent.choose_user}</h1>
      {hasOfflineProfiles ? (
        <>
          {renderUsers()}
          <OfflineModal
            isOpen={isDeleteModalOpen}
            offlineContent={offlineContent}
            closeOfflineModal={() => handleModal()}
            confirmAction={handleDeleteProfile}
          />
          <div className={styles.confirmContainer}>
            <hr className={styles.delimiter} />
            <div className={styles.confirmButton}>
              <Button
                onClick={() => confirmUserSelection()}
                disabled={!selectedUserId}
                text={offlineContent.confirm_button}
              />
            </div>
          </div>
        </>
      ) : (
        <div className={styles.noOfflineProfilesText}>{offlineContent.no_offline_profiles_available}</div>
      )}
    </div>
  );
}

OfflineUserProfiles.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  storedOfflineProfiles: PropTypes.object.isRequired,
  deleteOfflineProfilesAction: PropTypes.func.isRequired
};

const mapStateToProps = ({ offlineContentPlayer }) => ({ storedOfflineProfiles: offlineContentPlayer.profiles });

const mapDispatchToProp = {
  deleteOfflineProfilesAction: deleteOfflineProfiles
};

export default compose(
  withLocalizedContent('productDownload'),
  connect(mapStateToProps, mapDispatchToProp)
)(OfflineUserProfiles);
