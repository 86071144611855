import React from 'react';
import PropTypes from 'prop-types';
// Components
import HubModalLayout from '../../../components/HubModalLayout/HubModalLayout.js';
import Button, { buttonTypes } from '../../../components/Button/Button';
// Styles
import styles from './OfflineModal.scss';

function OfflineModal({ isOpen, offlineContent, closeOfflineModal, confirmAction }) {
  return (
    <div className={`${styles.offlineModal} ${isOpen ? styles.open : ''}`}>
      <HubModalLayout
        isOpen={isOpen}
        closeAction={closeOfflineModal}
        ariaLabel={offlineContent.offline_modal_aria_label}
      >
        <div
          data-testid="OFFLINE_MODAL"
          className={`${styles.modalContainer} ${isOpen ? styles.animationFadeIn : ''}`}
          aria-hidden={!isOpen}
        >
          <div className={styles.modalHeader}>
            <Button
              dataTestId="OFFLINE_MODAL_CLOSE_BUTTON"
              text={offlineContent.close}
              type={buttonTypes.CLOSE_WHITE}
              customClassName={styles.offlineModalCloseButton}
              onClick={e => {
                e.stopPropagation();
                closeOfflineModal();
              }}
            />
            <p className={styles.title}>{offlineContent.offline_modal_title}</p>
          </div>
          <hr />
          <div className={styles.modalOfflineContent}>
            <p className={styles.subtitle}>{offlineContent.offline_modal_subtext}</p>
            <p className={styles.warning}>{offlineContent.offline_modal_warning}</p>
          </div>
          <hr />
          <div className={styles.modalFooter}>
            <Button
              dataTestId="OFFLINE_MODAL_CANCEL_BUTTON"
              text={offlineContent.cancel}
              type={buttonTypes.GROUP}
              customClassName={styles.cancelButton}
              onClick={() => closeOfflineModal()}
            />
            <Button
              dataTestId="OFFLINE_MODAL_CONFIRM_BUTTON"
              text={offlineContent.remove}
              type={buttonTypes.THERE_BE_DRAGONS}
              customClassName={styles.confirmButton}
              onClick={() => {
                closeOfflineModal();
                confirmAction();
              }}
            />
          </div>
        </div>
      </HubModalLayout>
    </div>
  );
}
OfflineModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  offlineContent: PropTypes.object.isRequired,
  closeOfflineModal: PropTypes.func.isRequired,
  confirmAction: PropTypes.func.isRequired
};

export default OfflineModal;
